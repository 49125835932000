/**
 * @license
 * MyFonts Webfont Build ID 3614215, 2018-07-23T16:39:39-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: FrutigerLTPro-Light by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/frutiger/frutiger-pro-45-light/
 * 
 * Webfont: FrutigerLTPro-LightCn by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/frutiger/pro-47-light-condensed/
 * 
 * 
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3614215
 * Licensed pageviews: 15
 * Webfonts copyright: Copyright &#x00A9; 2014 - 2016 Monotype Imaging Inc. All rights reserved.
 * 
 * © 2018 MyFonts Inc
*/

@font-face {
  font-family: "FrutigerLTPro-Light";
  src: url("webfonts/372607_0_0.eot");
  src: url("webfonts/372607_0_0.eot?#iefix") format("embedded-opentype"),
    url("webfonts/372607_0_0.woff2") format("woff2"),
    url("webfonts/372607_0_0.woff") format("woff"),
    url("webfonts/372607_0_0.ttf") format("truetype");
}

@font-face {
  font-family: "FrutigerLTPro-LightCn";
  src: url("webfonts/372607_1_0.eot");
  src: url("webfonts/372607_1_0.eot?#iefix") format("embedded-opentype"),
    url("webfonts/372607_1_0.woff2") format("woff2"),
    url("webfonts/372607_1_0.woff") format("woff"),
    url("webfonts/372607_1_0.ttf") format("truetype");
}

/* Global */
* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100vh;
  margin: 0;
  background: #ededed;
}

html {
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

body {
  font-family: "FrutigerLTPro-Light", Courier, "Lucida Grande",
    "Lucida Sans Unicode", "Lucida Sans", Geneva, Verdana, sans-serif;
  font-size: 14px;
  line-height: 1.35em;
  color: #000;
  overscroll-behavior: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Phone Landscape ( + ) */
@media only screen and (min-width: 480px) {
  body {
    font-size: 16px;
  }
}

/* Tablet Portrait ( + ) */
@media only screen and (min-width: 768px) {
  body {
    font-size: 14px;
  }
}

/* Tablet Landscape ( + ) */
@media only screen and (min-width: 1024px) {
  body {
    font-size: 18px;
  }
}

/* Legacy Laptop ( + ) */
@media only screen and (min-width: 1100px) {
  body {
    font-size: 20px;
  }
}

/* Laptop ( + ) */
@media only screen and (min-width: 1280px) and (min-height: 700px) {
  body {
    font-size: 22px;
  }
}

/* Monitor ( + ) */
@media only screen and (min-width: 1440px) and (min-height: 700px) {
  body {
    font-size: 24px;
  }
}
